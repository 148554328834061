import React, { useEffect, useContext, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import linkContext from "../context/links/linkContext";
import Link from "./Link";
import SocialLink from "./SocialLink";
import { useMediaQuery } from "./hook.js";
import { Input } from "antd";
import NotLivePage from "./UserLinksDashboard/NotLivePage.jsx";
import CheckPassword from "./UserLinksDashboard/CheckPassword.jsx";
import { CircularProgress, Box, IconButton } from "@mui/material";
import { IoMdSearch } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import NotFound from "./NotFound.jsx";
import { debounce } from "lodash";
const { Search } = Input;

const View = () => {
  const location = useLocation();
  const pathName = location.pathname;
  const domainName = window.location.origin;
  const pathSegments = pathName.split("/");
  const param = pathSegments[pathSegments.length - 1];
  const context = useContext(linkContext);
  let {
    viewlinks,
    getUserid,
    userViewDetails,
    viewsetting,
    isLoading,
    setIsLoading,
    setShouldHideIcons,
  } = context;
  const [linkData, updateLinkData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [mainSettings, setMainSettings] = useState();
  const [isActive, setIsActive] = useState(null);
  const [isPasswordAvl, setIsPasswordAvl] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [page, setPage] = useState();
  const [newIconPath, setNewIconPath] = useState("");
  const [newTitle, setNewTitle] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handleToggleSearch = () => {
    setIsSearch(!isSearch);
  };

  const handleColse = () => {
    setDebouncedSearch("");
    setSearch("");
    setIsSearch(false);
  };

  useEffect(() => {
    userViewDetails(param);
  }, []);

  useEffect(() => {
    getUserid(param, domainName)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [param, domainName]);

  useEffect(() => {
    if (Array.isArray(viewsetting)) {
      const data = viewsetting?.filter((item) => item.userid === param);
      const mainSetting = data[0];
      setIsActive(mainSetting?.isActived);
      setMainSettings(mainSetting);
      setIsPasswordAvl(mainSetting?.protectPassword);
      setPage(mainSetting?.showLinksPerPage?.linksLimit);
      setNewIconPath(mainSetting?.pageTitleAndFeviconIconSetting?.feviconIcon);
      setNewTitle(mainSetting?.pageTitleAndFeviconIconSetting?.title);
    }
  }, [viewsetting, param]);

  const paginationSetting = mainSettings ? mainSettings.paginationSetting : "";

  useEffect(() => {
    if (viewlinks) {
      const newData = viewlinks?.filter((item) => item.userid === param);
      setFilteredData(newData);
    }
  }, [viewlinks, param]);

  function changeFaviconAndTitle(newIconPath, newTitle) {
    var favicon = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      favicon = document.createElement("link");
      favicon.rel = "icon";
      document.head.appendChild(favicon);
    }
    if (newIconPath) {
      favicon.href = newIconPath;
    } else {
      favicon.href = "./cbxFavIcon.svg";
    }
    if (newTitle) {
      document.title = newTitle;
    } else {
      document.title = "Cbxtree";
    }
  }

  changeFaviconAndTitle(newIconPath, newTitle);

  // useEffect(() => {
  const linkDataMemo = useMemo(() => {
    const currentDate = new Date();
    // const filterviewlink = filteredData
    return filteredData
      ?.filter((link) => {
        if (link.endDate) {
          const [day, month, year, hour, minute] =
            link.endDate.split(/[\/\s:]/);
          const endDate = new Date(year, month - 1, day, hour, minute);
          return endDate >= currentDate;
        } else {
          return true;
        }
      })
      .sort((a, b) => a.order - b.order);
    // const sortedLinks = filterviewlink
    //   .slice()
    //   .sort((a, b) => a.order - b.order);
    // updateLinkData(sortedLinks);
  }, [filteredData]);

  useEffect(() => {
    updateLinkData(linkDataMemo);
  }, [linkDataMemo]);

  const DriveUrl = (logoUrl) => {
    let link = logoUrl;
    if (logoUrl?.indexOf("drive.google.com") > -1) {
      var id = logoUrl.match(/[-\w]{25,}/)[0];
      link = "https://lh3.google.com/u/0/d/" + id;
    }
    return link;
  };

  const lgBackground = (bg) => {
    if (bg && bg.includes("→")) {
      const colors = bg.split(" → ");
      return `linear-gradient(to right, ${colors[0]}, ${colors[1]})`;
    }
    return bg;
  };

  const backgroundStyle = useMemo(() => {
    const bgImage = mainSettings?.backgroundImage;
    const bgLinear = mainSettings?.background;
    return {
      background: !bgImage
        ? bgLinear
          ? lgBackground(bgLinear)
          : "#fff"
        : `url(${DriveUrl(bgImage)})`,
      minHeight: "100vh",
      height: "100%",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      position: "relative",
      boxSizing: "border-box",
    };
  }, [mainSettings, DriveUrl, lgBackground]);

  // Search filter
  const isRowBased = useMediaQuery("(min-width: 500px)");
  const customStyles = {
    search: (isRowBased) => ({
      padding: "10px",
      marginInline: "20px",
      borderRadius: "8px",
      width: isRowBased ? "20%" : "60%",
      border: "none",
      cursor: "pointer",
      outline: `1px solid ${mainSettings?.paginationSetting?.paginationBtnBorder}`, //1
    }),
  };

  const styleSearch = useMemo(
    () => ({
      search: (isRowBased) => ({
        textAlign: isRowBased ? "right" : "center",
        paddingRight: isRowBased ? "50px" : "0px",
      }),
    }),
    []
  );

  // Debounce the search input
  const debouncedSetSearch = useMemo(
    () => debounce((value) => setDebouncedSearch(value), 300),
    []
  );

  useEffect(() => {
    debouncedSetSearch(search);

    return () => {
      debouncedSetSearch.cancel();
    };
  }, [search, debouncedSetSearch]);

  const searchData = useMemo(
    () =>
      linkData?.filter((item) => {
        const searchLower = debouncedSearch.toLowerCase();
        return (
          searchLower === "" ||
          item.description.toLowerCase().includes(searchLower) ||
          item.link.toLowerCase().includes(searchLower) ||
          item.linkType.toLowerCase().includes(searchLower) ||
          item.instantLink.toLowerCase().includes(searchLower)
        );
      }),
    [linkData, debouncedSearch]
  );

  //Pagination constant
  const recordsPerPage = page || 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  var records = searchData?.slice(firstIndex, lastIndex);
  const npage = Math.ceil(searchData?.length / recordsPerPage);

  const prevPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    setShouldHideIcons(true);

    return () => {
      setShouldHideIcons(false);
    };
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress size={80} sx={{ color: "#369978" }} />
      </Box>
    );
  }

  if (!isLoading) {
    if (!mainSettings && viewsetting?.success === false) {
      return <NotFound />;
    }
  }

  if (isPasswordAvl && !isShow) {
    return <CheckPassword setIsShow={setIsShow} userName={param} />;
  }

  if (mainSettings && !isActive) {
    return <NotLivePage />;
  }

  return (
    <div>
      {/* {mainSettings ? ( */}
      <div className="viewMainConainer" style={backgroundStyle}>
        <div
          style={{
            // position: "absolute",
            // right: 0,
            paddingRight: "10px",
            display: "flex",
            justifyContent: "end",
          }}
          id="google_translate_element"
        ></div>

        <div className="d-flex justify-content-center">
          {mainSettings && (
            <div className="m-2" style={{ textAlign: "center" }}>
              <div>
                {mainSettings?.logo && (
                  <img
                    src={DriveUrl(mainSettings?.logo)}
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      margin: "10px",
                      padding: "5px",
                      objectFit: "cover",
                    }}
                    alt="logo"
                  />
                )}
              </div>
              <div>
                <h2
                  style={{
                    color: `${mainSettings?.headersSettings.fontColor}`,
                    fontFamily: `${mainSettings?.headersSettings.fontFamily}`,
                    fontSize: `${mainSettings?.headersSettings.fontSize}`,
                    fontWeight: "bold",
                  }}
                >
                  {mainSettings?.header}
                </h2>
              </div>
            </div>
          )}
        </div>
        {mainSettings?.search && (
          <div
            style={styleSearch.search(isRowBased)}
            className="searchInputDiv"
          >
            {isSearch && mainSettings?.search ? (
              <input
                className="searchInput"
                type="text"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setCurrentPage(1);
                }}
                style={customStyles.search(isRowBased)}
                id="gsearch"
                name="gsearch"
                placeholder="Search"
              />
            ) : (
              <div> </div>
            )}

            {isSearch ? (
              <IconButton
                sx={{
                  padding: "4px",
                  border: `1px solid ${
                    mainSettings?.paginationSetting?.paginationBtnBorder ||
                    "#fff"
                  }} `,
                }}
                onClick={handleColse}
              >
                <IoClose
                  style={{
                    color: `${
                      mainSettings?.paginationSetting?.paginationBtnBorder ||
                      "#fff"
                    }`,
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  padding: "5px",
                  border: `1px solid ${
                    mainSettings?.paginationSetting?.paginationBtnBorder ||
                    "#fff"
                  }}`,
                }}
                onClick={handleToggleSearch}
              >
                <IoMdSearch
                  style={{
                    color: `${
                      mainSettings?.paginationSetting?.paginationBtnBorder ||
                      "#fff"
                    }`,
                  }}
                />
              </IconButton>
            )}
          </div>
        )}
        <div>
          {records.length > 0 &&
            records.map(
              (link, i) =>
                link.isTrue && (
                  <Link link={link} viewsetting={mainSettings} key={i} />
                )
            )}
        </div>
        <div className="d-flex justify-content-evenly my-3">
          {mainSettings?.footer &&
          mainSettings?.leftFooter.length > 0 &&
          mainSettings?.leftFooter[0]?.leftFooterName !== "" ? (
            <div>
              <a
                style={{
                  border: `1px solid ${mainSettings?.paginationSetting?.paginationBtnBorder}`, //2
                  color: `${mainSettings?.leftFooterSetting?.color}`,
                  fontFamily: `${mainSettings?.leftFooterSetting?.family}`,
                  fontSize: `${mainSettings?.leftFooterSetting?.size}`,
                  background: `${mainSettings?.leftFooterSetting?.background}`,
                }}
                type="button"
                className="btn btn-primary"
                href={mainSettings.leftFooter[0]?.leftFooterURL}
                target="_blank"
                rel="noreferrer"
              >
                {mainSettings?.leftFooter[0]?.leftFooterName}
              </a>
            </div>
          ) : (
            <div>
              <a
                type="button"
                style={{ visibility: "hidden" }}
                className="btn btn-primary"
              >
                ""
              </a>
            </div>
          )}
          {searchData?.length > page ? (
            <div className="d-flex justify-content-evenly">
              <div className="mx-2">
                <button
                  type="button"
                  style={{
                    border: `1px solid ${mainSettings?.paginationSetting?.paginationBtnBorder}`, //3
                    color: `${paginationSetting.color}`,
                    fontFamily: `${paginationSetting.family}`,
                    fontSize: `${paginationSetting.size}`,
                    background: `${paginationSetting.background}`,
                    borderRadius: "5px",
                  }}
                  className="btn btn-outline-primary"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
              </div>
              <div className="mx-2">
                <button
                  type="button"
                  style={{
                    border: `1px solid ${mainSettings?.paginationSetting?.paginationBtnBorder}`, //4
                    color: `${paginationSetting.color}`,
                    fontFamily: `${paginationSetting.family}`,
                    fontSize: `${paginationSetting.size}`,
                    background: `${paginationSetting.background}`,
                    borderRadius: "5px",
                  }}
                  className="btn btn-outline-primary"
                  onClick={nextPage}
                  disabled={currentPage === npage}
                >
                  Next
                </button>
              </div>
            </div>
          ) : null}

          {mainSettings?.footer &&
          mainSettings?.rightFooter.length > 0 &&
          mainSettings?.rightFooter[0]?.rightFooterName !== "" ? (
            <div>
              <a
                style={{
                  border: `1px solid ${mainSettings?.paginationSetting?.paginationBtnBorder}`, //5
                  color: `${mainSettings?.rightFooterSetting?.color}`,
                  fontFamily: `${mainSettings?.rightFooterSetting?.family}`,
                  fontSize: `${mainSettings?.rightFooterSetting?.size}`,
                  background: `${mainSettings?.rightFooterSetting?.background}`,
                  visibility: "",
                }}
                type="button"
                className="btn btn-primary"
                href={mainSettings?.rightFooter[0]?.rightFooterURL}
              >
                {mainSettings?.rightFooter[0]?.rightFooterName}
              </a>
            </div>
          ) : (
            <div>
              <a
                type="button"
                style={{ visibility: "hidden" }}
                className="btn btn-primary"
              >
                ""
              </a>
            </div>
          )}
        </div>

        {mainSettings?.header && (
          <div className="d-flex justify-content-center">
            <div className="companyTag">
              {/* <a
                  href="https://ceoitbox.com/"
                  target="_blank"
                  style={{ textDecoration: "none", color: "inherit" }}
                  rel="noreferrer"
                > */}
              <h3
                style={{
                  padding: "4px",
                  margin: "0px",
                  color: `${mainSettings?.headersSettings?.fontColor}`,
                  fontFamily: `${mainSettings?.headersSettings?.fontFamily}`,
                  fontSize: "25px",
                  fontWeight: "500",
                }}
              >
                {mainSettings?.companyName}
              </h3>
              {/* </a> */}
            </div>
          </div>
        )}

        {mainSettings?.socialLinks.length > 0 && (
          <div className="d-flex justify-content-center">
            {mainSettings?.socialLinks.map((socialLink, i) => {
              return (
                <SocialLink
                  viewsetting={mainSettings}
                  s
                  linktype={socialLink.linkType}
                  linkurl={socialLink.linkUrl}
                  key={i}
                />
              );
            })}
          </div>
        )}
        <div className="d-flex justify-content-center">
          <h6
            style={{
              color: `${
                mainSettings?.descriptionSettings?.descriptionColor ||
                mainSettings?.descriptionSettings?.color
              }`,
              fontFamily: `${mainSettings?.descriptionSettings.family}`,
              fontSize: `${mainSettings?.descriptionSettings.size}`,
              textAlign: "center",
              paddingBottom: "15px",
            }}
          >
            {mainSettings?.description}
          </h6>
        </div>
      </div>
      {/* ) : (
        <Box
          sx={{
            minHeight: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} sx={{ color: "#369978" }} />
        </Box>
      )} */}
    </div>
  );
};

export default View;
