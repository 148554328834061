import { useContext, useEffect, useState } from "react";
import {
  Container,
  Button,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Modal as Mui,
  Switch,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Tooltip } from "antd";
import { MdDelete, MdEmail, MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { IoMdShare } from "react-icons/io";
import { FaRegCopy } from "react-icons/fa6";
import { FaWhatsapp, FaFacebook } from "react-icons/fa";
import linkContext from "../../context/links/linkContext";
import { useNavigate } from "react-router-dom";
import LinksPreview from "./LinksPreview";
import { message } from "antd";
import PasswordCell from "./PasswordCell";
import { GoInfo } from "react-icons/go";
import { FcCollaboration } from "react-icons/fc";
import { FaRegShareSquare } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { IoQrCodeOutline } from "react-icons/io5";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import DeleteModal from "./Modals/DeleteModal";
import CreateNewCbxtreeModal from "./Modals/CreateNewCbxtreeModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "15px 20px",
};

const collaStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: "15px 20px",
};

const Dashboard = (props) => {
  let host = "http://localhost:3000/api";
  if (document.location.href.includes("localhost")) {
    host = "http://localhost:3000/api";
  } else {
    host = "api";
  }

  const navigate = useNavigate();
  const context = useContext(linkContext);
  let {
    userDetails,
    user,
    settings,
    links,
    getSettings,
    getLinks,
    UserData,
    allUsers,
    previewUrl,
    shareSettingData,
    fetchShareCbxtreeData,
  } = context;
  const [linkData, updateLinkData] = useState([]);
  const [mainSettings, setMainSettings] = useState([]);
  const [newFilteredData, setNewFilteredData] = useState([]);
  const [userName, setUserName] = useState("");
  const [nameSearchTerm, setNameSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const [filteredData, setFilteredData] = useState([]);
  const [tempData, setTempdata] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [userNameValue, setUserNameValue] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isChecked, setIsChecked] = useState(true);
  const [isShare, setIsShare] = useState();
  const [openAddCollaModal, setOpenAddCollaModal] = useState(false);
  const [userTreeName, setUserTreeName] = useState("");
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [emailsData, setEmailsData] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [displayData, setDisplayData] = useState(filteredData);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("token")) {
        await getLinks();
        getSettings();
      } else {
        navigate("/login");
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  // Get share treee
  useEffect(() => {
    fetchShareCbxtreeData();
  }, []);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const error = (message) => {
    messageApi.open({
      type: "error",
      content: message,
      duration: 5,
    });
  };

  const extractUserId = (url) => {
    const regex = /^https?:\/\/[^\/]+\/(.+)$/;
    const match = url.match(regex);
    if (match && match[1]) {
      return match[1];
    }
    return "";
  };

  const userCurrentAliasName = extractUserId(previewUrl);

  // useEffect(() => {
  //   if (user.userid && settings) {
  //     let data = user.userid.map((name, index) => {
  //       const userSetting = settings.find((setting) => setting.userid === name);
  //       const isActived = userSetting ? userSetting.isActived : true;
  //       const isPassword = userSetting ? userSetting.protectPassword : {};
  //       const isSecure = userSetting ? userSetting.isSecureLink : "";
  //       return {
  //         _id: index,
  //         name: name,
  //         isActived: isActived,
  //         isPassword: isPassword,
  //         isSecure: isSecure,
  //       };
  //     });
  //     data?.sort((a, b) => {
  //       return b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1;
  //     });
  //     setFilteredData(data);
  //     setTempdata(data);
  //   }
  // }, [user.userid, settings]);

  useEffect(() => {
    if (user.userid && settings && shareSettingData) {
      // Step 1: Create a map of shareTreeData for quick lookup
      const shareTreeDataMap = new Map();
      shareSettingData.forEach((shareItem) => {
        shareTreeDataMap.set(shareItem.userid, shareItem);
      });

      // Step 2: Combine user settings with shareTreeData
      let data = user.userid.map((name, index) => {
        const userSetting =
          (settings && settings.find((setting) => setting.userid === name)) ||
          {};
        // console.log("userSetting", userSetting);
        const shareTreeUser = shareTreeDataMap.get(name);

        const shareUser = allUsers.find(
          (u) => u._id === (shareTreeUser ? shareTreeUser.user : "")
        );

        return {
          _id: index,
          name: name,
          isActived: userSetting.isActived ?? true,
          isPassword: userSetting.protectPassword ?? {},
          isSecure: userSetting.isSecureLink ?? "",
          isQRCodeGenrate: userSetting.isQRCodeGenrate ?? false,
          highlight: !!shareTreeUser, // Check if the user is in shareTreeData
          user: userSetting.user || "",
          shareUserName: shareUser ? shareUser.name : "",
          sharedEmails: userSetting ? userSetting.collaborationEmails : [],
        };
      });

      // Step 3: Add any users in shareTreeData that are not in user.userid
      shareSettingData.forEach((shareItem, index) => {
        if (!user.userid.includes(shareItem.userid)) {
          const shareUser = allUsers.find((u) => u._id === shareItem.user);
          data.push({
            _id: data.length + index, // Ensure unique ID
            name: shareItem.userid,
            isActived: shareItem.isActived ?? true,
            isPassword: shareItem.protectPassword ?? {},
            isSecure: shareItem.isSecureLink ?? "",
            isQRCodeGenrate: shareItem.isQRCodeGenrate ?? false,
            highlight: true,
            user: shareItem.user || "",
            shareUserName: shareUser ? shareUser.name : "",
          });
        }
      });

      // Sort by isActived status
      // data?.sort((a, b) =>
      //   b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1
      // );

      data?.sort((a, b) => {
        if (a.name === userCurrentAliasName) return -1;
        if (b.name === userCurrentAliasName) return 1;
        return b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1;
      });

      setFilteredData(data);
      setIsDataLoading(false);
      setTempdata(data);
    }
  }, [user.userid, settings, shareSettingData, allUsers]);

  // Filter by status
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedStatus(value);

    if (value === "active") {
      setDisplayData(filteredData.filter((item) => item.isActived === true));
    } else if (value === "notactive") {
      setDisplayData(filteredData.filter((item) => item.isActived === false));
    } else {
      setDisplayData(filteredData);
    }
  };

  useEffect(() => {
    if (!selectedStatus) {
      setDisplayData(filteredData);
    }
  }, [filteredData, selectedStatus]);

  const handleResetFilter = () => {
    setNameSearchTerm("");
    setSelectedStatus("");
    setPage(0);
  };

  useEffect(() => {
    userDetails();
    getSettings();
    getLinks();
  }, []);

  const getEditarUserSetting = async (row) => {
    const response = await fetch(`${host}/settings/fetch-editar-settings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    const filterData = json?.filter((item) => item.userid == row);
    setEmailsData(filterData[0]);
  };

  const handleClick = (event, row, highlight, id) => {
    getEditarUserSetting(row);
    setIsShare(highlight);
    setUserTreeName(row);
    setUserNameValue(row);
    setAnchorEl(event.currentTarget);
    // const filterData = data?.filter((item) => item.userid == row);
    // console.log("filterData", filterData);
    // setEmailsData(filterData[0]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    { Header: "Link Name", accessor: "name" },
    { Header: "Preview", accessor: "preview" },
    { Header: "Password", accessor: "password" },
    { Header: "Action", accessor: "action" },
    { Header: "Secure links", accessor: "secure" },
    { Header: "Share", accessor: "share" },
  ];

  // Search
  useEffect(() => {
    const filtered = tempData?.filter((row) =>
      row?.name?.toLowerCase().includes(nameSearchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [tempData, nameSearchTerm]);

  // Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Create Modal
  const handleOpen = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Handle submit userName
  const handleSubmitUserName = async (e) => {
    e.preventDefault();
    Cookies.remove("cbxtree");
    Cookies.remove("userInfo");

    if (!userName.trim()) {
      return error("Username is required");
    }
    try {
      const response = await fetch(`${host}/settings/addsettings`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ userName }),
      });

      if (!response.ok) {
        console.error("Username must be unique : ", response.status);
        error("Username must be unique");
        throw new Error(`API call failed with status: ${response.status}`);
      }

      const responseData = await response.json();
      setUserName("");
      handleCloseModal();
      userDetails();
      getSettings();
      getLinks();
      success("Added successfully");
      setTimeout(() => {
        navigate("/link");
      }, 1000);
    } catch (error) {
      console.error(error.message, error);
    }
  };

  // Handle edit links
  const handleEditLinks = async (useridToMove) => {
    Cookies.remove("cbxtree");
    Cookies.remove("userInfo");

    try {
      const response = await fetch(
        `${host}/auth/updateUseridOrder/${user._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ useridToMove }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update userid order");
      }
      const responseText = await response.text();
      // console.log(responseText);

      navigate("/link");
      userDetails();
    } catch (error) {
      console.error("Error updating userid order:", error);
    }
  };

  useEffect(() => {
    UserData();
  }, []);

  const handleEditShareLink = (name, user) => {
    const data = allUsers?.filter(
      (item) => item.userid.includes(name) && item._id === user
    );

    if (data && data.length > 0) {
      Cookies.set("userInfo", JSON.stringify(data[0]), {
        expires: 365,
      });
    }
    Cookies.set("cbxtree", name, {
      expires: 365,
    });
    navigate("/link");
  };

  // Handle delete
  const deleteSettingsAndLinks = async (userid) => {
    try {
      const response = await fetch(
        `${host}/settings/deletesettings/${userid}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ userid }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete settings and links");
      }
      const data = await response.json();
      success("Deleted Successfully");
      console.log("Settings and links deleted successfully:", data.message);
    } catch (error) {
      error("Something went wrong");
      console.error("Error deleting settings and links:", error);
    }
  };

  // Delete cbxtree
  const handleDeleteClick = (userId) => {
    setDeleteUserId(userId);
    setOpenDeleteModal(true);
  };

  // Delete conformation modal
  const handleDeleteConfirm = async () => {
    try {
      await deleteSettingsAndLinks(deleteUserId);
      userDetails();
      setOpenDeleteModal(false);
    } catch (error) {
      console.error("Error deleting settings and links:", error);
    }
  };

  // Preview data -
  const getShareTreeSetting = async (ids, userIdd) => {
    const id = userIdd;
    try {
      const response = await fetch(`${host}/settings/fetchsettings/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      const data = json?.filter((item) => item.userid === ids);
      setMainSettings(data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  // Get share links
  const getShareTreeLinks = async (ids, userIdd) => {
    const id = userIdd;
    try {
      const response = await fetch(`${host}/links/fetchlinks/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      const data = json?.filter((item) => item.userid === ids);
      setNewFilteredData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePreviewCbxtree = async (userIdd, ids) => {
    setIsLoading(true);
    // setSelectedUserName(userIdd);
    await getShareTreeSetting(userIdd, ids);
    await getShareTreeLinks(userIdd, ids);
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (settings) {
  //     const data = settings?.filter((item) => item.userid === selectedUserName);
  //     setMainSettings(data[0]);
  //   }
  // }, [settings, selectedUserName]);

  // useEffect(() => {
  //   if (links) {
  //     const newData = links?.filter((item) => item.userid === selectedUserName);
  //     setNewFilteredData(newData);
  //   }
  // }, [links, selectedUserName]);

  useEffect(() => {
    const currentDate = new Date();
    // Filter links based on endDate
    if (Array.isArray(newFilteredData)) {
      const filterviewlink = newFilteredData?.filter((link) => {
        if (link.endDate) {
          const [day, month, year, hour, minute] =
            link.endDate.split(/[\/\s:]/);
          const endDate = new Date(year, month - 1, day, hour, minute);
          return endDate >= currentDate;
        } else {
          return true;
        }
      });
      // Sort links based on the 'order' property
      const sortedLinks = filterviewlink
        .slice()
        .sort((a, b) => a.order - b.order);
      // Update linkData when links change
      updateLinkData(sortedLinks);
    }
  }, [newFilteredData]);

  // Copy
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        success("Copied");
        console.log("Text copied to clipboard");
        setAnchorEl(null);
      })
      .catch((err) => {
        error("Something went wrong");
        console.error("Failed to copy: ", err);
      });
  };

  const handleToggle = (e, linkName) => {
    const isToggle = e.target.checked;
    const updatedData = filteredData?.map((item) => {
      if (item.name === linkName) {
        return { ...item, isActived: isToggle };
      }
      return item;
    });

    updatedData?.sort((a, b) => {
      return b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1;
    });

    setFilteredData(updatedData);
    updateToggleStatus(linkName, isToggle);

    if (isToggle) {
      success(`Link ${linkName} is enabled successfully`);
    } else {
      error(`Link ${linkName} is disabled successfully`);
    }
  };

  // Update toggle status for cbxtree
  function updateToggleStatus(linkName, isToggle) {
    const apiUrl = `${host}/settings/updateToggleStatus`;

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ linkName, isToggle }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleToggleLinks = (e, treeName, id) => {
    const isCheck = e.target.checked;
    const updatedData = filteredData?.map((item) => {
      if (item.name === treeName) {
        return { ...item, isSecure: isCheck };
      }
      return item;
    });

    // updatedData?.sort((a, b) => {
    //   return b.isActived === a.isActived ? 0 : b.isActived ? 1 : -1;
    // });

    setFilteredData(updatedData);
    handleToggleSecureLinks(treeName, isCheck, id);
  };

  // Update all links status
  const handleToggleSecureLinks = (treeName, isCheck, id) => {
    const apiUrl = `${host}/links/secure-all-links`;

    fetch(apiUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ treeName, isCheck, id }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Collaborative logic
  const handleOpenModal = () => {
    setEmails([]);
    setAnchorEl(null);
    setOpenAddCollaModal(!openAddCollaModal);
  };

  const handleCloseCollaModal = () => {
    setOpenAddCollaModal(false);
  };

  const handleAddEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      error("Please enter a valid email address.");
      return;
    }
    if (email && !emails.includes(email)) {
      setEmails((prevEmails) => [...prevEmails, email]);
      setEmail("");
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails((prevEmails) =>
      prevEmails.filter((email) => email !== emailToDelete)
    );
  };

  const handleSave = () => {
    if (emails.length === 0) {
      error("Email is required");
      return;
    }
    handleSaveEmailAddress(emails);
    handleCloseCollaModal();
    setEmails([]);
  };

  // Add People Email Id
  const handleSaveEmailAddress = async (emails) => {
    // e.preventDefault();
    const name = user && user.name;
    const ownerEmail = user && user.email;
    try {
      const response = await fetch(`${host}/settings/add-collaboration-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          userTreeName,
          emails,
          name,
          ownerEmail,
          isChecked,
        }),
      });

      const result = await response.json();
      // console.log("result", result);

      if (response.ok) {
        console.log("added");
        success(result.message);
        getSettings();
        setEmail("");
        setOpenAddCollaModal(false);
      } else {
        error("Email already exists or invalid");
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Delete Email Id
  const handleDeleteEmailId = async (emailId) => {
    try {
      const response = await fetch(
        `${host}/settings/delete-collaboration-email`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("token"),
          },
          body: JSON.stringify({ userTreeName, emailId }),
        }
      );

      const result = await response.json();

      if (response.ok) {
        success(result.message);
        getSettings();
        setEmail("");
        setEmailsData((prevData) => ({
          ...prevData,
          collaborationEmails: prevData.collaborationEmails.filter(
            (email) => email !== emailId
          ),
        }));
        // setOpenAddCollaModal(false);
      } else {
        error("Email already exists");
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [openQRModal, setOpenQRModal] = useState(false);
  const [alias, setAlias] = useState("");
  const [fullUrl, setFullUrl] = useState("");
  const handleCloseQRModal = () => setOpenQRModal(false);

  const handleOpenQRCode = (aliasName) => {
    const baseUrl = "https://www.cbxtree.in/";
    const newUrl = `${baseUrl}${aliasName}`;
    setAlias(aliasName);
    setFullUrl(newUrl);
    setOpenQRModal(true);
  };

  useEffect(() => {
    fetchShareCbxtreeData();
  }, []);

  const handleCbxtreeClick = (cbxtreeName) => {
    const url =
      user && user.domain
        ? `${user.domain}/${cbxtreeName}`
        : `https://www.cbxtree.in/${cbxtreeName}`;

    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: "#f8f8f8",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDataLoading}
      >
        <CircularProgress sx={{ color: "#0bd7f3" }} />
      </Backdrop>

      <Box
        sx={{
          background: "#f8f8f8",
          border: "1px solid #f8f8f8",
          pb: 5,
          height: "100%",
        }}
      >
        {contextHolder}

        <Box
          sx={{
            //  mt: "100px",
            mt: "50px",
            width: "100%",
            height: "100%",
          }}
        >
          <Container>
            {displayData ? (
              <Paper sx={{ pt: 2, borderRadius: "20px" }}>
                <Box
                  className="createBtnBox"
                  sx={{
                    display: "flex",
                    alignItems: "center ",
                    gap: "1rem",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    mb: 1,
                    pl: 2,
                    pr: 2,
                  }}
                >
                  <Button
                    onClick={handleOpen}
                    sx={{ padding: "8px 30px" }}
                    className="createBtn"
                  >
                    {" "}
                    Create a New Tree
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    <TextField
                      className="filterSearchInput"
                      label="Search by Name"
                      id="outlined-search"
                      type="search"
                      fullWidth
                      margin="normal"
                      size="small"
                      value={nameSearchTerm}
                      onChange={(e) => {
                        setNameSearchTerm(e.target.value);
                        setPage(0);
                      }}
                      sx={{
                        width: "300px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "green",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B4D33B",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#4D8733",
                          },
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          textTransform: "none",
                          color: "#4D8733",
                        },
                      }}
                    />

                    <FormControl
                      size="small"
                      sx={{
                        width: "200px",
                        mt: "7px",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "green",
                          },
                          "&:hover fieldset": {
                            borderColor: "#B4D33B",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#4D8733",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "#4D8733",
                          "&.Mui-focused": {
                            color: "#4D8733",
                          },
                        },
                      }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        sx={{
                          textTransform: "none",
                          color: "#4D8733",
                        }}
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedStatus}
                        label="Status"
                        onChange={handleChange}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="notactive">Not Active</MenuItem>
                      </Select>
                    </FormControl>

                    <Button
                      onClick={handleResetFilter}
                      sx={{
                        background: "#0bd7f3",
                        textTransform: "none",
                        mt: "7px",
                        color: "#fff",
                        "&:hover": {
                          background: "#09b1c3",
                        },
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                </Box>

                <TableContainer size="small">
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          background: "#F1F1F1",
                        }}
                      >
                        {columns?.map((column, i) => (
                          <>
                            <Tooltip
                              title={
                                column.Header === "Secure links" &&
                                "Secure all links within this CBXTree by toggling the switch"
                              }
                              placement="top"
                              arrow
                            >
                              <TableCell
                                align={i === 0 ? "start" : "center"}
                                key={column.accessor}
                                style={{
                                  backgroundColor: column.backgroundColor,
                                  fontWeight: 520,
                                  color: "#0C1228",
                                }}
                              >
                                {column.Header}
                                {column.Header === "Secure links" && (
                                  <GoInfo
                                    style={{
                                      color: "#4f8b33",
                                      marginLeft: "4px",
                                      marginBottom: "5px",
                                      cursor: "pointer",
                                    }}
                                  />
                                )}
                              </TableCell>
                            </Tooltip>
                          </>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {(rowsPerPage > 0
                        ? displayData.slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                        : displayData
                      ).map((row, index) => (
                        <TableRow
                          key={row._id}
                          sx={{
                            background: row.isActived ? "#fff" : "#f8f9fa",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.02)",
                            },
                          }}
                        >
                          {columns?.map((column, index) => (
                            <TableCell
                              sx={{
                                p: "12px",
                                background: row.highlight ? "#F3FCFA" : "",
                              }}
                              key={index}
                              align="center"
                            >
                              {column.accessor === "preview" ? (
                                <Box>
                                  <button
                                    style={{
                                      fontSize: "14px",
                                      color: "#4D8733",
                                    }}
                                    className="btn btn-light shadow-none"
                                    type="button"
                                    data-bs-toggle="modal"
                                    data-bs-target="#previewModel"
                                    // onClick={() =>
                                    //   setSelectedUserName(row.name)
                                    // }
                                    onClick={() =>
                                      handlePreviewCbxtree(row.name, row.user)
                                    }
                                  >
                                    <i className="fa-solid fa-eye"></i>
                                    <span
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "14px",
                                        color: "#4D8733",
                                        fontWeight: "500",
                                      }}
                                    >
                                      PREVIEW
                                    </span>
                                  </button>
                                </Box>
                              ) : column.accessor === "share" ? (
                                <Box>
                                  <Button
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        row.name,
                                        row.highlight,
                                        row.user
                                      )
                                    }
                                    sx={{ color: "#4D8733" }}
                                    startIcon={<IoMdShare />}
                                    size="small"
                                  >
                                    Share
                                  </Button>

                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    sx={{
                                      boxShadow: 2,
                                      ".MuiPaper-root": {
                                        borderRadius: "12px",
                                        boxShadow: 2,
                                      },
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        copyToClipboard(
                                          user && user.domain
                                            ? `${user.domain}/${userNameValue}`
                                            : `https://www.cbxtree.in/${userNameValue}`
                                        )
                                      }
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <FaRegCopy color="#333" />
                                      <Typography> Copy </Typography>
                                    </MenuItem>
                                    {!isShare && (
                                      <MenuItem
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                        onClick={handleOpenModal}
                                      >
                                        <FcCollaboration />
                                        <Typography> Collaboration </Typography>
                                      </MenuItem>
                                    )}
                                    <MenuItem
                                      onClick={() => {
                                        const url =
                                          user && user.domain
                                            ? `${user.domain}/${userNameValue}`
                                            : `https://www.cbxtree.in/${userNameValue}`;

                                        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                          url
                                        )}`;
                                        window.open(facebookShareUrl, "_blank");
                                        handleClose();
                                      }}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <FaFacebook color="#1877F2" />
                                      <Typography>Facebook</Typography>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        const url =
                                          user && user.domain
                                            ? `${user.domain}/${userNameValue}`
                                            : `https://www.cbxtree.in/${userNameValue}`;
                                        const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
                                          url
                                        )}`;
                                        window.open(whatsappShareUrl, "_blank");
                                        handleClose();
                                      }}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <FaWhatsapp color="#25D366" />
                                      <Typography>WhatsApp</Typography>
                                    </MenuItem>
                                    <MenuItem
                                      onClick={() => {
                                        const url =
                                          user && user.domain
                                            ? `${user.domain}/${userNameValue}`
                                            : `https://www.cbxtree.in/${userNameValue}`;
                                        const emailSubject =
                                          "Check out this link";
                                        const emailBody = `Hey! I found this interesting link: ${url}`;
                                        const emailShareUrl = `mailto:?subject=${encodeURIComponent(
                                          emailSubject
                                        )}&body=${encodeURIComponent(
                                          emailBody
                                        )}`;
                                        window.location.href = emailShareUrl;
                                        handleClose();
                                      }}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <MdEmail color="gray" />
                                      <Typography>Email</Typography>
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              ) : column.accessor === "password" ? (
                                <Box sx={{ cursor: "pointer" }}>
                                  <PasswordCell password={row.isPassword} />
                                </Box>
                              ) : column.accessor === "secure" ? (
                                <Box sx={{ cursor: "pointer" }}>
                                  <Tooltip
                                    title={
                                      row.isSecure
                                        ? "This CBXTree is secured"
                                        : "Secure all links within this CBXTree"
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <Switch
                                      checked={row.isSecure}
                                      onChange={(e) =>
                                        handleToggleLinks(e, row.name, row.user)
                                      }
                                      size="small"
                                      sx={{
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                          color: "#4D8733",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                          {
                                            backgroundColor: "#4D8733",
                                          },
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              ) : column.accessor === "action" ? (
                                <Box>
                                  <Tooltip title="Edit" placement="top" arrow>
                                    <IconButton
                                      onClick={() =>
                                        row.highlight
                                          ? handleEditShareLink(
                                              row.name,
                                              row.user
                                            )
                                          : handleEditLinks(row.name)
                                      }
                                    >
                                      <CiEdit size={18} color="#4D8733" />
                                    </IconButton>
                                  </Tooltip>
                                  {!row.highlight ? (
                                    <Tooltip
                                      title={"Delete"}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteClick(row.name)
                                        }
                                      >
                                        <MdDelete
                                          size={18}
                                          style={{
                                            color: "#4D8733",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip
                                      title={
                                        "You do not have permission to delete this CBXTree."
                                      }
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton>
                                        <MdDelete
                                          size={18}
                                          style={{
                                            color: "gray",
                                            cursor: "default",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  {row.highlight ? (
                                    <Tooltip
                                      title={
                                        "You do not have permission to active this CBXTree."
                                      }
                                      placement="top"
                                      arrow
                                    >
                                      <Switch
                                        checked={row.isActived}
                                        size="small"
                                        sx={{
                                          cursor: "default",
                                          "& .MuiSwitch-switchBase.Mui-checked":
                                            {
                                              color: "gray",
                                            },
                                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                            {
                                              backgroundColor: "gray",
                                            },
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Switch
                                      disabled={row.highlight}
                                      checked={row.isActived}
                                      onChange={(e) =>
                                        handleToggle(e, row.name)
                                      }
                                      size="small"
                                      sx={{
                                        "& .MuiSwitch-switchBase.Mui-checked": {
                                          color: row.highlight
                                            ? "gray"
                                            : "#4D8733",
                                        },
                                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                                          {
                                            backgroundColor: row.highlight
                                              ? "gray"
                                              : "#4D8733",
                                          },
                                      }}
                                    />
                                  )}

                                  {row.isQRCodeGenrate && (
                                    <Tooltip
                                      title={"View Your QR Code"}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleOpenQRCode(row.name)
                                        }
                                      >
                                        <IoQrCodeOutline
                                          size={18}
                                          color="#4D8733"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              ) : (
                                <Tooltip
                                  title={
                                    (row.highlight ||
                                      row.sharedEmails?.length > 0) && (
                                      <span>
                                        {row.highlight && (
                                          <>
                                            Shared by {row.shareUserName}
                                            <br />
                                            You do not have the necessary
                                            permissions to change the alias and
                                            domain.
                                          </>
                                        )}
                                        {row.sharedEmails?.length > 0 && (
                                          <>
                                            {row.highlight && <br />}
                                            This Cbxtree has been shared with
                                            the following emails :
                                            <br />
                                            <span>
                                              {row.sharedEmails.join(", ")}
                                            </span>
                                          </>
                                        )}
                                      </span>
                                    )
                                  }
                                  placement="top"
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "10px",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      color: "#00bce0",
                                    }}
                                    onClick={() => handleCbxtreeClick(row.name)}
                                  >
                                    {(row.highlight ||
                                      row.sharedEmails?.length > 0) && (
                                      <FaRegShareSquare
                                        style={{
                                          cursor: "pointer",
                                          color: "#4D8733",
                                        }}
                                      />
                                    )}
                                    {row[column.accessor]}
                                  </Box>
                                </Tooltip>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Tabel pagination */}
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={filteredData?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    "& .MuiTablePagination-selectLabel": {
                      marginTop: "15px !important",
                    },
                    "& .MuiTablePagination-displayedRows": {
                      marginTop: "15px !important",
                    },
                    textAlign: "center",
                  }}
                />
              </Paper>
            ) : (
              <div style={{ padding: "4rem" }}>
                <p>Loading...</p>
              </div>
            )}
          </Container>
        </Box>

        {/* Mui create new userName modal */}
        <CreateNewCbxtreeModal
          openModal={openModal}
          handleCloseModal={handleCloseModal}
          setUserName={setUserName}
          handleSubmitUserName={handleSubmitUserName}
          userName={userName}
        />

        {/* Delete conformation modal */}
        <DeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          handleDeleteConfirm={handleDeleteConfirm}
        />

        {/* Show QR Code Modal */}
        <QRCodeModal
          open={openQRModal}
          handleClose={handleCloseQRModal}
          qrUrl={fullUrl}
          userName={alias}
        />

        {/* Add Collaborative People Modal */}
        <Modal
          open={openAddCollaModal}
          onClose={handleCloseCollaModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className="muiModalBox" sx={collaStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: "22px" }}
                id="simple-modal-title"
                variant="h6"
                component="h2"
              >
                Add Editor Email Address
              </Typography>
              <IconButton sx={{ p: "4px" }} onClick={handleCloseCollaModal}>
                <IoIosCloseCircleOutline size={30} />
              </IconButton>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                mt: "20px",
              }}
            >
              <input
                className="userEmailInput"
                style={{ width: "80%" }}
                fullWidth
                id="outlined-basic"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Button
                className="addMoreBtn"
                onClick={handleAddEmail}
                startIcon={<img src="plus.png" />}
              >
                {emails.length > 0 ||
                emailsData?.collaborationEmails?.length > 0
                  ? "Add More"
                  : "Add"}
                {/* Add More */}
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <FormControlLabel
                size="small"
                sx={{ textTransform: "none" }}
                control={
                  <Checkbox
                    size="small"
                    checked={isChecked}
                    onChange={handleCheckBoxChange}
                  />
                }
                label="Notify Me"
              />
            </Box>

            {/* Render emails box */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              {emailsData?.collaborationEmails?.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "0px 0px 0px 10px",
                    borderRadius: "4px",
                    gap: "20px",
                  }}
                >
                  <Typography>{item}</Typography>
                  <IconButton onClick={() => handleDeleteEmailId(item)}>
                    <MdDeleteOutline color="red" size={16} />
                  </IconButton>
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              {emails?.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "0px 0px 0px 10px",
                    borderRadius: "4px",
                    gap: "20px",
                  }}
                >
                  <Typography>{item}</Typography>
                  <IconButton onClick={() => handleDeleteEmail(item)}>
                    <MdDeleteOutline color="red" size={16} />
                  </IconButton>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={handleSave}
                variant="contained"
                sx={{
                  fontSize: "16px",
                  m: "16px 0px",
                  borderColor: "#4D8733",
                  background: "#4D8733",
                  // background: "#f2ffef",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "#376927",
                    background: "#376927",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Preview Modal */}
        <div
          className="modal fade"
          id="previewModel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog modal-lg">
            <div
              className="modal-content"
              style={{
                marginTop: "65px",
                borderRadius: newFilteredData?.length > 0 ? "" : "20px",
              }}
            >
              {newFilteredData?.length > 0 ? (
                <LinksPreview
                  viewsetting={mainSettings}
                  viewlinks={newFilteredData}
                />
              ) : (
                <div
                  style={{
                    margin: "10px 10px 5px 10px",
                    padding: "10px",
                    textAlign: "center",
                    color: "#777e90",
                    fontWeight: 500,
                  }}
                >
                  {isLoading ? (
                    <Typography>Loading...</Typography>
                  ) : (
                    <h4>Please add links to get preview</h4>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;

{
  /* <Modal
          open={openAddCollaModal}
          onClose={handleCloseCollaModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box className="muiModalBox" sx={collaStyle}>
            <Typography
              sx={{ fontWeight: 500, fontSize: "22px" }}
              id="simple-modal-title"
              variant="h6"
              component="h2"
            >
              Add Collaborative Email Address
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                mt: "20px",
              }}
            >
              <input
                className="userEmailInput"
                style={{ width: "80%" }}
                fullWidth
                id="outlined-basic"
                placeholder="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
              <Button className="addMoreBtn" startIcon={<img src="plus.png" />}>
                Add More
              </Button>
            </Box>

            
            <Box
              sx={{
                display: "flex",
                alignItems: "stretch",
                gap: "20px",
                flexWrap: "wrap",
                mt: "10px",
              }}
            >
              {emails?.collaborationEmails?.map((item, i) => (
                <Box
                  key={i}
                  mt={1}
                  mb={1}
                  sx={{
                    boxShadow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: "0px 0px 0px 10px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography>{item}</Typography>
                  <IconButton onClick={() => handleDeleteEmailId(item)}>
                    <MdDeleteOutline color="red" size={16} />
                  </IconButton>
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  m: "16px 0px",
                  textTransform: "none",
                  borderColor: "#4D8733",
                  color: "#4D8733",
                  fontWeight: 500,
                  "&:hover": {
                    color: "#4f8b33",
                    border: "1px solid #4f8b33",
                  },
                }}
                onClick={handleCloseCollaModal}
                style={{ marginTop: "16px" }}
              >
                Close
              </Button>
              <Button
                onClick={handleSaveEmailAddress}
                variant="contained"
                sx={{
                  m: "16px 0px",
                  borderColor: "#4D8733",
                  background: "#4D8733",
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    borderColor: "#376927",
                    background: "#376927",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal> */
}
