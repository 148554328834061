import { React, useContext, useEffect } from "react";
import AllRoutes from "./components/Routes/AllRoutes";
import useDynamicFaviconAndTitle from "./components/DynamicFaviconAndTitle/useDynamicFaviconAndTitle";
import { IconButton, Box } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import linkContext from "./context/links/linkContext";
import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAdminPanelSettings } from "react-icons/md";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(linkContext);
  let { user, userDetails, shouldHideIcons } = context;
  useDynamicFaviconAndTitle();

  useEffect(() => {
    userDetails();
  }, []);

  const handleClickSecurityIcon = () => {
    navigate("/users");
  };

  const pathArr = ["/", "/login", "/signup", "/sendotp", "/users"];
  const shouldHideIcons1 = pathArr.includes(location.pathname);

  return (
    <Box sx={{ position: "relative" }}>
      <AllRoutes />

      {!shouldHideIcons1 && !shouldHideIcons && (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            position: "fixed",
            maxHeight: "100vh",
            bottom: "15px",
            right: "10px",
            zIndex: 100,
          }}
        >
          <Box>
            {" "}
            <Tooltip title={<span>Email : {user && user.email}</span>}>
              <IconButton
                sx={{
                  color: "#fff",
                  background: "#00bce0",
                  "&:hover": {
                    background: "#09b1c3",
                  },
                }}
              >
                <FaUserCircle />
              </IconButton>
            </Tooltip>
          </Box>
          {user && user.role === "Admin" && (
            <Box>
              <Tooltip title="Admin dashboard">
                <IconButton
                  onClick={handleClickSecurityIcon}
                  sx={{
                    color: "#fff",
                    background: "#00bce0",
                    "&:hover": {
                      background: "#09b1c3",
                    },
                  }}
                >
                  <MdAdminPanelSettings />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default App;

// import { Routes, Route, Navigate } from "react-router-dom";
// import Navbar from "./components/Navbar/Navbar";
// import Home from "./components/Home";
// import CopySettings from "./components/CopySettings";
// import Alert from "./components/Alert";
// import Login from "./components/Auth/Login";
// import Signup from "./components/Auth/Signup";
// import Users from "./components/AdminDashboard/Users";
// import View from "./components/View";
// import ShowNavbar from "./components/ShowNavbar";
// import SendOtp from "./components/Auth/SendOtp";
// import Dashboard from "./components/UserLinksDashboard/Dashboard";
// import HomePage from "./components/HomePage/HomePage";

// const [alert, setAlert] = useState(null);
// const [alertId, setAlertId] = useState(0);

// const showAlert = (message, type) => {
//   setAlert({
//     id: alertId,
//     msg: message,
//     type: type,
//   });
//   setAlertId((prevId) => prevId + 1);
//   setTimeout(() => {
//     setAlert(null);
//   }, 1500);
// };

{
  /* <ShowNavbar>
        <Navbar />
        <Alert alert={alert} />
      </ShowNavbar>

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<View showAlert={showAlert} />} />
        <Route path="/link" element={<Home showAlert={showAlert} />} />
        <Route path="/login" element={<Login showAlert={showAlert} />} />
        <Route path="/signup" element={<Signup showAlert={showAlert} />} />
        <Route path="/sendotp" element={<SendOtp showAlert={showAlert} />} />
        <Route exact path="/" element={<Navigate to="/login" />} />
        <Route path="/users" element={<Users />} />
        <Route
          path="/settings"
          element={<CopySettings showAlert={showAlert} />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard showAlert={showAlert} />}
        />
      </Routes> */
}
