import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography, Popover, IconButton, Tooltip } from "@mui/material";
import { IoMdArrowBack, IoMdClose } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import "./NewFeatures.css";
import linkContext from "../../context/links/linkContext";
import debounce from "lodash.debounce";
import { MdFullscreen } from "react-icons/md";
import FeatureImgPreviewModal from "./FeatureImgPreviewModal";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { message } from "antd";
import EditFeatureModal from "./EditFeatureModal";

const ShowingNewFeaturesModal = ({
  id,
  handleCloseUpdateModal,
  openPopover,
  openUpdateModal,
}) => {
  const location = useLocation();
  let { newFeaturesData, getAllNewFeaturesData, user, handleDeleteFeature } =
    useContext(linkContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [searchQuery, setSearchQuery] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [featureId, setFeatureId] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 5,
    });
  };

  const handleOpenEditModal = (item) => {
    setSelectedFeature(item);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedFeature(null);
  };

  const handleOpenDeleteModal = (id) => {
    setFeatureId(id);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleOpenPreviewModal = (img) => {
    setPreviewImg(img);
    setOpenModal(true);
  };

  const closedPreviewModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    getAllNewFeaturesData();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    debouncedSearch.cancel();
  };

  const filteredFeatures = newFeaturesData?.filter((item) =>
    item.featureName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle delete feature
  const handleDelete = async (featureId) => {
    await handleDeleteFeature(featureId);
    setFeatureId("");
    success("Delete successfully.");
    handleCloseDeleteModal();
  };

  return (
    <Box>
      {contextHolder}
      <Popover
        id={id}
        open={openPopover}
        anchorEl={openUpdateModal}
        onClose={handleCloseUpdateModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          borderRadius: "36px",
          mt: "20px",

          "& .MuiPaper-root": {
            borderRadius: "36px",
            p: "15px 20px",
            border: "1px solid #EDF2E6",
            background: "#FFF",
            boxShadow: "15px 11px 100px 0px rgba(79, 91, 121, 0.25)",
            width: "386px",
          },
        }}
      >
        <Box>
          {/* ----------- Heading -------------- */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton size="small" onClick={handleCloseUpdateModal}>
              <IoMdArrowBack size={24} color="#00bce0" />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                position: "relative",
              }}
            >
              <input
                type="text"
                placeholder="Search"
                className="feature-search"
                onChange={handleSearchChange}
                value={searchQuery}
              />
              <GoSearch
                className="feature-search-icon"
                size={13}
                color="#181818"
              />
              {searchQuery && (
                <IconButton
                  onClick={handleClearSearch}
                  className="clearIcon"
                  sx={{
                    p: "2px",
                    "&:hover": {
                      color: "#00bce0",
                    },
                  }}
                >
                  <IoMdClose size={14} />
                </IconButton>
              )}
              <IconButton
                size="small"
                onClick={handleCloseUpdateModal}
                sx={{
                  "&:hover": {
                    color: "#00bce0",
                  },
                }}
              >
                <IoMdClose size={24} />
              </IconButton>
            </Box>
          </Box>

          {/* ----------- Heading Text-------------- */}
          <Box sx={{ mt: 1, mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                sx={{
                  color: "#181818",
                  fontWeight: 700,
                  fontSize: "20px",
                  letterSpacing: "0.8px",
                  textTransform: "uppercase",
                }}
              >
                What’s New
                {/* ✨ */}
              </Typography>
              <img src="/star.svg" alt="star" />
            </Box>

            <Typography
              sx={{
                fontSize: "12px",
                color: "#181818",
                letterSpacing: "0.48px",
                fontWeight: 400,
              }}
            >
              We are excited to introduce the latest updates to our site!
            </Typography>
          </Box>

          {/* ------------- Render features ------------------ */}
          <Box className="render-feature-container">
            {filteredFeatures?.map((item) => {
              return (
                <Box className="showing-feature-box">
                  <Box
                    className="render-feature-video"
                    sx={{
                      boxShadow: 1,
                      borderRadius: "30px",
                      width: "175px",
                      height: "100px",
                    }}
                  >
                    {item.videoUrl ? (
                      <iframe
                        width="175px"
                        height="100"
                        style={{ borderRadius: "30px", zIndex: 100 }}
                        src={item.videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                      ></iframe>
                    ) : item.imgUrl ? (
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "30px",
                          boxShadow: 1,
                          width: "175px",
                          height: "100px",
                        }}
                      >
                        <img
                          src={item.imgUrl}
                          alt={item.featureName}
                          style={{
                            width: "175px",
                            height: "100px",
                            borderRadius: "30px",
                            objectFit: "cover",
                          }}
                          className="preview-image"
                        />
                        <IconButton
                          onClick={() => handleOpenPreviewModal(item.imgUrl)}
                          size="small"
                          sx={{
                            background: "#000",
                            color: "#fff",
                            position: "absolute",
                            right: "10px",
                            top: "60px",
                            transition: "transform 0.3s ease",
                            "&:hover": {
                              background: "#000",
                              color: "#fff",
                              "& .fullscreen-icon": {
                                transform: "scale(1.2)",
                              },
                            },
                          }}
                        >
                          <MdFullscreen className="fullscreen-icon" />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#737C95",
                          fontSize: "12px",
                          fontWeight: 600,
                          lineHeight: "141.916%",
                        }}
                      >
                        {formatDate(item.month)}
                      </Typography>

                      {/* Actions */}
                      {user &&
                        user.role === "Admin" &&
                        location.pathname === "/users" && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Tooltip title="Edit" arrow placement="top">
                              <IconButton
                                // onClick={() => handleEditClick(item)}
                                onClick={() => handleOpenEditModal(item)}
                                sx={{
                                  p: "3px",
                                  "&:hover": {
                                    color: "#00bce0",
                                  },
                                }}
                              >
                                <CiEdit size={14} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" arrow placement="top">
                              <IconButton
                                onClick={() => handleOpenDeleteModal(item._id)}
                                sx={{
                                  p: "3px",
                                  "&:hover": {
                                    color: "red",
                                  },
                                }}
                              >
                                <MdDeleteOutline size={14} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                    </Box>

                    <Typography
                      sx={{
                        fontSize: "15px",
                        color: "#181818",
                        fontWeight: 600,
                        lineHeight: "141.916%",
                      }}
                    >
                      {item.featureName}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>

      {/* Feature image preview modal */}
      <FeatureImgPreviewModal
        openModal={openModal}
        closedPreviewModal={closedPreviewModal}
        previewImg={previewImg}
      />

      {/* Delete confirmation modal */}
      <DeleteConfirmationModal
        openDeleteModal={openDeleteModal}
        handleCancel={handleCloseDeleteModal}
        featureId={featureId}
        handleDeleteFeature={handleDelete}
      />

      {/* Edit modal */}
      <EditFeatureModal
        openEditModal={openEditModal}
        handleCloseEditModal={handleCloseEditModal}
        selectedFeature={selectedFeature}
      />
    </Box>
  );
};

export default ShowingNewFeaturesModal;
