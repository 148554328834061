import React, { useContext, useEffect, useState } from "react";
import { LuUsers } from "react-icons/lu";
import { FaLinkSlash } from "react-icons/fa6";
import "./InfoCard.css";
import linkContext from "../../context/links/linkContext";
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from "react-icons/io";

const InfoCard = ({ users, usersCount }) => {
  const context = useContext(linkContext);
  let {
    getLinksCount,
    linkCount,
    userLogs,
    getUserLogs,
    getLinksLogs,
    linksLogs,
  } = context;

  // const [userCount, setUserCount] = useState(0);
  // const [loadingUsers, setLoadingUsers] = useState(true);

  function logProgress(percentage) {
    const integerPart = Math.floor(percentage);
    if (percentage < 0) return Math.min(integerPart * -1, 100);
    return Math.min(integerPart, 100);
  }

  useEffect(() => {
    getUserLogs();
    getLinksCount();
    getLinksLogs();
    // if (users) {
    //   setUserCount(users?.length);
    //   setLoadingUsers(false);
    // }
  }, []);

  return (
    <div>
      <div className="card-container">
        <div className="logs-card">
          <div className="card-content">
            <div className="first-line">
              <small>Total Links Created Today</small>
              <FaLinkSlash />
            </div>

            <div className="second-line-parent">
              <div className="second-line">
                <h2>{linksLogs?.countToday}</h2>

                {linksLogs?.percentageChange < 0 ? (
                  <small className="decrease">
                    {" "}
                    <IoIosArrowRoundDown />
                    {linksLogs?.percentageChange * -1}%
                  </small>
                ) : (
                  <small className="increase">
                    <IoIosArrowRoundUp />
                    {linksLogs?.percentageChange}%
                  </small>
                )}
              </div>

              <small className="prev">vs. previous day</small>
            </div>

            <div className="first-line">
              <small>0%</small>
              <small>100%</small>
            </div>
            <div
              className="progress-bar"
              style={{
                backgroundColor:
                  linksLogs?.percentageChange < 0 ? "#ffe4e4" : "#e4faff",
              }}
            >
              <div
                className="progress"
                style={{
                  width: `${logProgress(linksLogs?.percentageChange)}%`,
                  backgroundColor:
                    linksLogs?.percentageChange < 0 ? "#aa3939" : "#378ba3",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="logs-card">
          <div className="card-content">
            <div className="first-line">
              <small>New Clients</small>
              <LuUsers />
            </div>

            <div className="second-line-parent">
              <div className="second-line">
                <h2>{userLogs?.countToday}</h2>
                {userLogs?.percentageChange < 0 ? (
                  <small className="decrease">
                    {" "}
                    <IoIosArrowRoundDown />
                    {userLogs?.percentageChange * -1}%{" "}
                  </small>
                ) : (
                  <small className="increase">
                    <IoIosArrowRoundUp />
                    {userLogs?.percentageChange}%
                  </small>
                )}
              </div>

              <small className="prev">vs. previous day</small>
            </div>

            <div className="first-line">
              <small>0%</small>
              <small>100%</small>
            </div>
            <div
              className="progress-bar"
              style={{
                backgroundColor:
                  userLogs?.percentageChange < 0 ? "#ffe4e4" : "#e4faff",
              }}
            >
              <div
                className="progress"
                style={{
                  width: `${logProgress(userLogs?.percentageChange)}%`,
                  backgroundColor:
                    userLogs?.percentageChange < 0 ? "#aa3939" : "#378ba3",
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="logs-card">
          <div className="card-content">
            <div className="first-line">
              <small>Total Clients</small>
              <LuUsers />
            </div>

            <div className="second-line-parent">
              <div className="second-line">
                <h2>{usersCount}</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="logs-card">
          <div className="card-content">
            <div className="first-line">
              <small>Total Links Created</small>
              <FaLinkSlash />
            </div>

            <div className="second-line-parent">
              <div className="second-line">
                <h2>{linkCount}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard;
